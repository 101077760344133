import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 0
export const listName = 'sl.suporte.project'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Chave', name: 'chave', active: false, sortable: true, ordering: 3},
    {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 4},
    {label: 'Líder', name: 'lider', active: true, sortable: true, ordering: 5},
    {label: 'Cliente', name: 'cliente', active: true, sortable: true, ordering: 6},
    {label: 'Solicitações abertas', name: 's-abertas', active: true, sortable: true, ordering: 6},
    {label: 'Solicitações fechadas', name: 's-fechadas', active: true, sortable: true, ordering: 6},
    {label: 'Visibilidade', name: 'visibilidade', active: true, sortable: true, ordering: 1},
], listVersion)
