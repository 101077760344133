<script>
import ProjectList from "components/suporte/components/projetos/List"
import MenuOptionsItem from "components/layout/context-menu/context-window-options-item"
import {date, UPopover, UInput, USelect} from "uloc-vue"
import windowProjeto from './projetos/window/projeto'
// import {datePtToEn} from "@/utils/date";
import {listProject as list} from "@/domain/project/services"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
export default {
  name: 'SuporteProjetos',
  mixins: [],
  components: {
    ProjectList,
    MenuOptionsItem,
    UPopover,
    ECol,
    ERow,
    UInput,
    USelect
  },
  data () {
    return {
      table: {
        serverData: [],
        loading: false,
        filters: {
          busca: null,
          id: null,
          tipo: null,
          active: [true],
          cliente: null,
          lider: null,
          permission: null,
          public: null
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 20
        }
      }
    }
  },
  mounted() {
    this.load()
  },
  watch: {
    'table.filters.active' (v) {
      this.$nextTick(() => {
        this.load()
      })
    }
  },
  computed: {
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let extraFilters = []

      this.table.filters.busca && extraFilters.push(`&search=${this.table.filters.busca}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.tipo && extraFilters.push(`&type=${this.table.filters.tipo}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active.join(',')}`)
      this.table.filters.cliente && extraFilters.push(`&client=${this.table.filters.cliente.id}`)
      this.table.filters.lider && extraFilters.push(`&owner=${this.table.filters.lider.id}`)
      this.table.filters.permission && extraFilters.push(`&permission=${this.table.filters.permission}`)
      this.table.filters.public && extraFilters.push(`&public=${this.table.filters.public}`)

      /*if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }*/

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    },
    windowProjeto: windowProjeto,
    novoProjeto () {
      this.windowProjeto()
    },
    editarProjeto (projeto) {
      this.windowProjeto(projeto.id)
    }
  },
  meta: {
    title: 'Projetos',
    name: 'Projetos'
  }
}
</script>

<template>
  <div v-if="$route.name === 'suporte.projetos'" class="scontent">
    <ul class="snav">
      <li><a @click="$router.push({name: 'suporte.dashboard'})">Suporte</a></li>
      <li class="disable cursor-pointer" @click="load">Projetos</li>
    </ul>
    <div class="stitle">
      <h2 class="cursor-pointer" @click="load">Projetos</h2>
      <div class="sopts">
        <u-btn label="Criar" no-caps color="blue-8" @click="novoProjeto"/>
        <u-btn flat size="sm" class="m-l-xs">
          <i class="fa fa-ellipsis-h font-14" />
          <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
            <div>
              <ul>
                <menu-options-item label="Configurações" close  />
              </ul>
            </div>
          </u-popover>
        </u-btn>
      </div>
    </div>
    <div class="stask-search">
      <e-row>
        <e-col style="max-width: 200px"><u-input placeholder="Pesquisar em projetos" @keydown.enter="load" v-model="table.filters.busca" hide-underline inverted-light class="bg-grey-2 no-box-shadow b" :after="[{icon: 'search'}]" /></e-col>
        <e-col style="max-width: 200px">
          <u-select
              hide-underline inverted-light class="bg-grey-2 no-box-shadow b m-l"
              multiple v-model="table.filters.active" :options="[
              {label: 'Ativos', value: true},
              {label: 'Projetos desativados', value: false},
          ]" />
<!--          <u-btn class="m-l-xs">
            <div class="lbl">
              <span>Somente ativos</span>
              <i class="fa fa-ellipsis-h font-14" />
            </div>
            <u-popover ref="popover" class="window-context-menu menu-profile min" anchor="bottom left" self="top left" :offset="[0, 15]" style="min-width: 172px">
              <div>
                <ul>
                  <menu-options-item label="Configurações" close  />
                </ul>
              </div>
            </u-popover>
          </u-btn>-->
        </e-col>
      </e-row>
    </div>
    <project-list :data="table.serverData" :request="request" :load="load" :options="table" @editar="editarProjeto" />
  </div>
  <router-view v-else />
</template>
