<template>
  <div class="task-list m-t">
    <u-table
        ref="table"
        color="primary"
        :data="data"
        :columns="columns"
        :visible-columns="visibledColumns"
        row-key="id"
        :pagination.sync="options.serverPagination"
        selection="multiple"
        :loading="options.loading"
        class="task-table"
        @request="request"
        :rows-per-page-options="[20, 50, 100]"
        :hide-no-results-label="true"
    >
      <u-tr slot="header" slot-scope="props">
<!--        <u-th auto-width>
          <u-checkbox
              v-model="props.selected"
              :indeterminate="props.partialSelected"
              size="sm"
          />
        </u-th>-->
        <u-th v-for="col in props.cols" :key="col.name" :props="props">
          <template>
            {{ col.label }}
          </template>
          <div v-if="col.name === 'options'" class="text-center">
            <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                   no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
          </div>
        </u-th>
      </u-tr>

      <u-tr slot="body" slot-scope="props"
            :props="props">
<!--        <u-td class="pos-rlt" auto-width>
        </u-td>-->
        <u-td style="max-width: 50px; min-width: 50px; width: 50px" key="id" :props="props">
          {{ props.row.id }}
        </u-td>
        <u-td key="nome" :props="props">
          <router-link tag="a" :to="{name: 'suporte.projetos.projeto', params: {id: props.row.id}}" class="font-bold">{{props.row.name}}</router-link>
        </u-td>
        <u-td key="chave" :props="props">
          {{props.row.code}}
        </u-td>
        <u-td class="td-limit l-200" key="tipo" :props="props">
          {{ props.row.type ? props.row.type.name : '-' }}
        </u-td>
        <u-td key="lider" :props="props">
          <a>
            <div v-if="props.row.owner" class="person-avatar-name">
              <div class="person-avatar avatar md inline">
              <img v-if="props.row.owner.photo" width="25" class="" :src="props.row.owner.photo"/>
              <div v-else class="img-fake">{{ firstLettersName(props.row.owner.name) }}</div>
              </div>
              <span class="name">{{props.row.owner.name}}</span>
            </div>
          </a>
        </u-td>
        <u-td key="cliente" :props="props">
          <a>
            <div v-if="props.row.client" class="person-avatar-name">
              <div class="person-avatar avatar md inline">
                <img v-if="props.row.client.photo" width="25" class="" :src="props.row.client.photo"/>
                <div v-else class="img-fake">{{ firstLettersName(props.row.client.name) }}</div>
              </div>
              <span class="name">{{props.row.client.name}}</span>
            </div>
          </a>
        </u-td>
        <u-td key="s-abertas" :props="props">
          <router-link tag="a" :to="{name: 'suporte.projetos.projeto.fila', params: {id: props.row.id, fila: 'opened'}}">{{props.row.taskOpened}} aberta(s)</router-link>
        </u-td>
        <u-td key="s-fechadas" :props="props">
          <router-link tag="a" :to="{name: 'suporte.projetos.projeto.fila', params: {id: props.row.id, fila: 'closed'}}">{{props.row.taskClosed}} fechada(s)</router-link>
        </u-td>
        <u-td key="visibilidade" :props="props">
          <div v-if="props.row.active">
          <span v-if="typeof permissions[props.row.permission] !== 'undefined'">
            {{permissions[props.row.permission].min}}
          </span> <span v-if="props.row.public" class="u-chip u-chip-small bg-green font-12 text-white m-l-xs">Público</span>
          </div>
          <div v-else>
            <span class="u-chip u-chip-small bg-red-3 font-12 font-bold text-white">Desativado</span>
          </div>
        </u-td>
<!--        <u-td :title="props.row.resumo" class="td-limit l-130" key="titulo"
              :props="props">
          {{ props.row.description }}
        </u-td>-->
        <u-td class="text-center" key="options" :props="props">
          <e-btn-table-options>
            <menu-options>
              <ul>
                <menu-options-item close label="Abrir" @click="$emit('abrir')" />
                <menu-options-item close label="Editar projeto" @click="$emit('editar', props.row)" />
              </ul>
            </menu-options>
          </e-btn-table-options>
        </u-td>
      </u-tr>
    </u-table>
  </div>
</template>

<script>
import {listName, listStorage} from "./config/list"
import listOptions from "@/reuse/list/listOptions"
import {LocalStorage, UCheckbox, UTable, UTd, UTh, UTr, debounce} from "uloc-vue"
// import ECol from "components/layout/components/Col"
// import ERow from "components/layout/components/Row"
import {EBtnTableOptions} from "@/plugins/uloc-erp"
import MenuOptions from "components/layout/context-menu/context-window-options"
import MenuOptionsItem from '../../../layout/context-menu/context-window-options-item'
// import ListMenu from "@/reuse/list/ListMenu"
import {PERMISSIONS} from "@/domain/project/helpers/types"
export default {
  name: "ProjectList",
  props: {
    data: {
      type: Array
    },
    request: {
      type: Function
    },
    load: {
      type: Function
    },
    options: {
      type: Object
    }
  },
  data () {
    return {
      listName: listName,
      listStorage: this.listStorage,
      columns: this.listStorage.map(o => {
        return {
          ...o,
          field: o.name,
          align: 'left'
        }
      }).concat([
        {
          name: 'options',
          required: true,
          label: '',
          field: 'options',
          sortable: false
        }
      ])
    }
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    },
    permissions () {
      return PERMISSIONS
    }
  },
  watch: {
    'options.filters.busca': debounce(function () {
      this.load()
    }, 500)
  },
  methods: {
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      console.log('Ok', newConfig)
      LocalStorage.set(this.listName, newConfig)
      this.listStorage = newConfig
    },
    abrir (t) {}
  },
  components: {
    UTable,
    UTh,
    UTr,
    UTd,
    // UCheckbox,
    // ECol,
    // ERow,
    EBtnTableOptions,
    MenuOptions,
    MenuOptionsItem,
    // UInput,
    // UPopover,
    // USelect
    // ListMenu
  }
}
</script>
